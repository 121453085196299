import React, { useState, useEffect, useRef } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { toolbarPlugin, ToolbarSlot } from "@react-pdf-viewer/toolbar";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";

const PdfFireViewer = ({ pdfBlob, canDownload, nameFile }) => {
  const fileUrlRef = useRef(null);
  const viewerRef = useRef(null);
  const urlWasCreated = useRef(false);

  const toolbarPluginInstance = useRef(toolbarPlugin()).current;
  const { Toolbar } = toolbarPluginInstance;
  const zoomPluginInstance = useRef(zoomPlugin()).current;
  const pageNavigationPluginInstance = useRef(pageNavigationPlugin()).current;

  useEffect(() => {
    // Revoga URL anterior, se necessário
    if (urlWasCreated.current && fileUrlRef.current) {
      URL.revokeObjectURL(fileUrlRef.current);
      fileUrlRef.current = null;
      urlWasCreated.current = false;
    }

    // Define nova URL
    if (typeof pdfBlob === "string") {
      fileUrlRef.current = pdfBlob;
    } else if (pdfBlob instanceof Blob || pdfBlob instanceof File) {
      fileUrlRef.current = URL.createObjectURL(pdfBlob);
      urlWasCreated.current = true;
    }
  }, [pdfBlob]);

  const downloadBlob = async (url, filename = "documento.pdf") => {
    try {
      const res = await fetch(url);
      const blob = await res.blob();
      const blobUrl = URL.createObjectURL(blob);
  
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Erro ao baixar PDF:", error);
    }
  };
  
  // Util para extrair nome do arquivo a partir da URL
  const getFileName = (originalName) => {
    if (typeof originalName === "string") {
      const baseName = originalName.substring(0, originalName.lastIndexOf(".")) || originalName;
      return baseName + ".pdf";
    }
    return "documento.pdf";
  };
  
  

  return fileUrlRef.current ? (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.10.377/build/pdf.worker.min.js">
      <div
        style={{
          border: "1px solid #dcdcdc",
          borderRadius: "8px",
          backgroundColor: "#f9f9f9",
          padding: "10px",
          height: "70vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#ececec",
            padding: "8px",
            borderRadius: "5px",
            marginBottom: "8px",
            justifyContent: "space-between",
          }}
        >
          <Toolbar>
            {(props: ToolbarSlot) => {
              const {
                CurrentPageInput,
                GoToNextPage,
                GoToPreviousPage,
                NumberOfPages,
                Zoom,
                ZoomIn,
                ZoomOut,
              } = props;
              return (
                <>
                  <div style={{ padding: "0px 2px" }}>
                    <ZoomOut />
                  </div>
                  <div style={{ padding: "0px 2px" }}>
                    <Zoom />
                  </div>
                  <div style={{ padding: "0px 2px" }}>
                    <ZoomIn />
                  </div>
                  <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                    <GoToPreviousPage />
                  </div>
                  <div style={{ padding: "0px 2px", width: "100px" }}>
                    <CurrentPageInput /> / <NumberOfPages />
                  </div>
                  <div style={{ padding: "0px 2px" }}>
                    <GoToNextPage />
                  </div>
                </>
              );
            }}
          </Toolbar>

          {canDownload && (
                            <button
                              onClick={() =>
                                downloadBlob(fileUrlRef.current, getFileName(nameFile))
                              }
                              style={{
                                marginLeft: "10px",
                                padding: "5px 10px",
                                borderRadius: "5px",
                                border: "1px solid #ccc",
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                              }}
                            >
                              Baixar PDF
                            </button>
                          )}
        </div>

        <div
          style={{
            flex: 1,
            overflow: "auto",
            border: "1px solid #e0e0e0",
            padding: "5px",
            borderRadius: "4px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "28%",
              left: "5%",
              width: "85%",
              height: "69%",
              backgroundColor: "transparent",
              zIndex: 9999,
              pointerEvents: "all",
            }}
          ></div>
          <style>
            {`
              .rpv-core__textbox {
                width: 50% !important;
              }
            `}
          </style>
          <Viewer
            ref={viewerRef}
            fileUrl={fileUrlRef.current}
            plugins={[
              toolbarPluginInstance,
              zoomPluginInstance,
              pageNavigationPluginInstance,
            ]}
          />
        </div>
      </div>
    </Worker>
  ) : (
    <p>Carregando PDF...</p>
  );
};

export default PdfFireViewer;
