import React, { Component } from 'react';
import Select from 'react-select';
import Axios from '../../config/Axios';
import Page from '../default/Page';
import intl from 'react-intl-universal';
import FroalaEditorComponent from '../../config/FroalaEditorComponent';
import AltCheckbox from '../../components/AltCheckbox';
import ScrollBar from '../../components/ScrollBar';
import ScopeEdit from './ScopeEdit';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import { Alert } from '../../components/Alert';
import { getRequisites } from '../../actions/ActionRequisite';
import moment from 'moment';
import { can, checkModule } from '../../config/Permissions';
import url from '../../config/Url';
import { oLocalStorage } from '../../config/EncodedLocalStorage';
import './styles/audit.scss';

class Audit extends Component {
  constructor(oProps) {
    super(oProps);
    this.alertWrapper = React.createRef();

    this.state = {
      oAudit: oProps.oAudit,
      bLoading: true,
      oRequisites: oProps.oAudit ? oProps.oAudit.audit_requisites.map(oRequisite => oRequisite.req_nid) : [],
      norm_nid: oProps.oAudit && oProps.oAudit.norm ? oProps.oAudit.norm.norm_nid : '',
      selectedNorm: oProps.oAudit
        ? {
          label: oProps.oAudit.norm.norm_cinitials,
          value: oProps.oAudit.norm.norm_nid
        }
        : '',
      audiInternal: oProps.oAudit ? oProps.oAudit.audi_bisinternal : true,
      audi_cdescription: oProps.oAudit ? oProps.oAudit.audi_cdescription : '',
      aSelectedResponsibles: oProps.oAudit ? oProps.oAudit.audit_responsibles.map(usr => usr.user.user_nid) : [],
      aSelectedAuditors: oProps.oAudit ? oProps.oAudit.audit_auditors.map(usr => usr.user.user_nid) : [],
      selectedLocal:
        oProps.oAudit && oProps.oAudit.local_audit
          ? {
            label: oProps.oAudit.local_audit.locl_cfantasyname,
            value: oProps.oAudit.local_audit.locl_nid
          }
          : '',
      localSend: false,
      audi_dexecutionbegin: oProps.oAudit
        ? moment.parseZone(oProps.oAudit.audi_dexecutionbegin).format('YYYY-MM-DD')
        : '',
      audi_dexecutionend: oProps.oAudit ? moment.parseZone(oProps.oAudit.audi_dexecutionend).format('YYYY-MM-DD') : '',
      audi_dexecutionbeginTime: oProps.oAudit
        ? moment.parseZone(oProps.oAudit.audi_dexecutionbegin).format('HH:mm')
        : '',
      audi_dexecutionendTime: oProps.oAudit ? moment.parseZone(oProps.oAudit.audi_dexecutionend).format('HH:mm') : '',
      locl_nid: undefined,
      ChangeNormAudit: oProps.oAudit ? false : true,
      aUsers: [],
      aNorms: [],
      aLocals: [],
      cEditorContent: oProps.oAudit ? oProps.oAudit.audi_cdescription : ''
    };
  }

  componentDidMount = () => {
    const { history } = this.props;
    checkModule(history, 'audit-management');

    this.getAllCombos().then(() => {
      this.setState({ bLoading: false });
    });
  };

  getAllCombos = async () => {
    try {
      const oResponse = await Axios.get('/getNormModal');

      const { aUsers, aNorms, aLocals } = oResponse.data;

      this.setState({
        aUsers: aUsers.map(user => ({ label: user.user_cname, value: user.user_nid })),
        aNorms: aNorms
          .filter(aNorm => aNorm.norm_bisactive)
          .map(oNorm => ({ label: oNorm.norm_cinitials, value: oNorm.norm_nid })),
        aLocals: aLocals.map(aLocal => ({ label: aLocal.locl_cfantasyname, value: aLocal.locl_nid }))
      });
    } catch (error) {
      console.error('Falha ao buscar informações:', error);
    }
  };


  setAllRequisites = () => {
    const { norm_nid } = this.state;
    this.setState({ bLoading: true });
    getRequisites(norm_nid).then(oData => {
      let ids = [];
      let getIds = currentNode => {
        currentNode.map(req => {
          ids.push(req.req_nid);
          if (req.children_requisites_count > 0) getIds(req.recursive_children_requisite);
        });
      };
      getIds(oData.aRequisites);

      this.setScopeRequisistes(ids);
    }).finally(() => {
      this.setState({ bLoading: false });
    });
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value });
  };

  setChangeNormAudit = elSelectedOption => {
    this.hideAlert();
    this.setState(
      {
        norm_nid: elSelectedOption.value,
        ChangeNormAudit: true
      },
      () => {
        this.setAllRequisites();
      }
    );
  };

  setChangeSelectedUser = (aUsr, type) => {
    this.hideAlert();

    this.setState(prevState => ({
      [type]: prevState[type].filter(oUsr => Number(oUsr) !== Number(aUsr.value))
    }));
  };

  handleChangeCustom = (elSelectedOption, type) => {
    const { ChangeNormAudit, oAudit, aSelectedResponsibles, aSelectedAuditors, aUsers } = this.state;

    if (['aSelectedResponsibles', 'aSelectedAuditors'].includes(type)) {
      let aSelectedUsers = type === 'aSelectedResponsibles' ? aSelectedResponsibles : aSelectedAuditors;

      if (oAudit && elSelectedOption.length < aSelectedUsers.length) {
        const optionsValue = elSelectedOption.map(aOpt => aOpt.value);
        let diffIdUser = aSelectedUsers.filter(tag => !optionsValue.includes(tag));
        let aUser = aUsers.filter(oUsr => Number(oUsr.value) === Number(diffIdUser));
        const confirmationMessage =
          type === 'aSelectedResponsibles'
            ? intl.get('Audit.confirmacao_exclusao_responsavel', {
              cResponsible: aUser[0].label
            })
            : intl.get('Audit.confirmacao_exclusao_auditor', {
              cAuditor: aUser[0].label
            });

        this.setState({
          rcmpAlert: (
            <SweetAlert
              showCancel
              confirmBtnText={intl.get('confirmar')}
              cancelBtnText={intl.get('cancelar')}
              title=""
              onConfirm={() => this.setChangeSelectedUser(aUser[0], type)}
              onCancel={this.hideAlert}
            >
              {confirmationMessage}
            </SweetAlert>
          )
        });
        return;
      }
    }

    if (!ChangeNormAudit && type === 'norm_nid') {
      this.setState({
        rcmpAlert: (
          <SweetAlert
            showCancel
            confirmBtnText={intl.get('confirmar')}
            cancelBtnText={intl.get('cancelar')}
            cancelBtnBsStyle="danger"
            onConfirm={() => this.setChangeNormAudit(elSelectedOption)}
            onCancel={this.hideAlert}
          >
            {intl.get('Audit.confirmacao_alteracao', { cInitials: oAudit.norm.norm_cinitials })}
          </SweetAlert>
        )
      });
      return;
    }

    if (type === 'norm_nid' || type === 'locl_nid') {
      this.setState(
        {
          [type]: elSelectedOption ? elSelectedOption.value : ''
        },
        () => {
          if (type === 'norm_nid' && elSelectedOption) {
            this.setAllRequisites();
          }
        }
      );
    } else {
      this.setState({
        [type]: elSelectedOption.map(opt => opt.value)
      });
    }
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  successMessage = cMsg => {
    this.hideAlert();
    this.componentDidMount();
    this.setAlert('success', cMsg);
  };

  setScopeRequisistes = oRequisites => {
    this.setState({
      oRequisites
    });
  };

  setAlert = (cType, cMsg) => {
    this.setState(
      {
        rcmpAlertMsg: (
          <Alert type={cType} isOpen onCloseAlert={() => { }}>
            {cMsg}
          </Alert>
        )
      },
      () => this.alertWrapper?.current?.scrollIntoView()
    );
  };

  saveAudit = evt => {
    evt.preventDefault();
    const {
      norm_nid,
      audi_dexecutionbegin,
      audi_dexecutionend,
      audi_dexecutionbeginTime,
      audi_dexecutionendTime,
      aSelectedResponsibles,
      aSelectedAuditors,
      locl_nid,
      oRequisites,
      audiInternal,
      cEditorContent
    } = this.state;

    const { onSuccess } = this.props;

    if (
      moment(audi_dexecutionbegin + audi_dexecutionbeginTime, 'YYYY/MM/DD HH:mm').format('YYYY-MM-DD HH:mm:ss') >
      moment(audi_dexecutionend + audi_dexecutionendTime, 'YYYY/MM/DD HH:mm').format('YYYY-MM-DD HH:mm:ss')
    ) {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('Audit.erro_data_inicial')}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );

      return;
    }

    if (moment(audi_dexecutionbegin) > moment('2100/12/31') || moment(audi_dexecutionend) > moment('2100/12/31')) {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('warning_date')}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );

      return;
    }

    if (!norm_nid) {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('Audit.erro_selecionar_norma')}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );

      return;
    }

    if (aSelectedAuditors.length === 0) {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('Audit.erro_selecionar_auditor')}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );

      return;
    }

    this.setState({
      localSend: true
    });

    Axios.post('audit', {
      norm_nid,
      audi_dexecutionbegin: moment(audi_dexecutionbegin + audi_dexecutionbeginTime, 'YYYY/MM/DD HH:mm').format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      audi_dexecutionend: moment(audi_dexecutionend + audi_dexecutionendTime, 'YYYY/MM/DD HH:mm').format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      locl_nid,
      responsibles: aSelectedResponsibles,
      auditors: aSelectedAuditors,
      scopeRequisites: oRequisites,
      audi_cdescription: cEditorContent,
      audi_bisinternal: audiInternal,
      cEditorContent
    })
      .then(res => {
        this.setState(
          {
            localSend: false
          },
          () => {
            onSuccess(intl.get('Audit.criacao_sucesso'));
          }
        );
      })
      .catch(oError => {
        if (typeof oError.response.data === 'string') {
          this.setState(
            {
              localSend: false,
              rcmpAlertMsg: (
                <Alert type="error" isOpen onCloseAlert={() => { }}>
                  {oError.response.data}
                </Alert>
              )
            },
            () => this.alertWrapper?.current?.scrollIntoView()
          );
        } else {
          this.setState(
            {
              localSend: false,
              rcmpAlertMsg: (
                <Alert type="error" isOpen onCloseAlert={() => { }}>
                  {Object.keys(oError.response.data.errors).map((cKey, i) => (
                    <p> - {oError.response.data.errors[cKey]}</p>
                  ))}
                </Alert>
              )
            },
            () => this.alertWrapper?.current?.scrollIntoView()
          );
        }
      });
  };

  updateAudit = e => {
    e.preventDefault();

    const {
      norm_nid,
      audi_dexecutionbegin,
      audi_dexecutionend,
      audi_dexecutionbeginTime,
      audi_dexecutionendTime,
      aSelectedResponsibles,
      aSelectedAuditors,
      oAudit,
      locl_nid,
      oRequisites,
      audiInternal,
      cEditorContent
    } = this.state;

    const { onSuccess } = this.props;

    if (
      moment(audi_dexecutionbegin + audi_dexecutionbeginTime, 'YYYY/MM/DD HH:mm').format('YYYY-MM-DD HH:mm:ss') >
      moment(audi_dexecutionend + audi_dexecutionendTime, 'YYYY/MM/DD HH:mm').format('YYYY-MM-DD HH:mm:ss')
    ) {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('Audit.erro_data_inicial')}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );

      return;
    }

    if (moment(audi_dexecutionbegin) > moment('2100-12-31') || moment(audi_dexecutionend) > moment('2100-12-31')) {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('warning_date')}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );

      return;
    }

    if (!norm_nid) {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('Audit.erro_selecionar_norma')}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );

      return;
    }

    if (aSelectedAuditors.length === 0) {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('Audit.erro_selecionar_auditor')}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );

      return;
    }

    this.setState({
      localSend: true
    });

    Axios.put(`audit/${oAudit.audi_nid}`, {
      norm_nid,
      audi_dexecutionbegin: moment(audi_dexecutionbegin + audi_dexecutionbeginTime, 'YYYY/MM/DD HH:mm').format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      audi_dexecutionend: moment(audi_dexecutionend + audi_dexecutionendTime, 'YYYY/MM/DD HH:mm').format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      locl_nid,
      responsibles: aSelectedResponsibles.map(aSelectedUser =>
        aSelectedUser.value ? aSelectedUser.value : aSelectedUser
      ),
      auditors: aSelectedAuditors.map(aSelectedAuditor =>
        aSelectedAuditor.value ? aSelectedAuditor.value : aSelectedAuditor
      ),
      scopeRequisites: oRequisites,
      audi_cdescription: cEditorContent,
      audi_bisinternal: audiInternal,
      cEditorContent
    })
      .then(res => {
        this.setState(
          {
            localSend: false
          },
          () => {
            onSuccess(intl.get('Audit.alteracao_sucesso'));
          }
        );
      })
      .catch(oError => {
        if (typeof oError.response.data === 'string') {
          this.setState(
            {
              localSend: false,
              rcmpAlertMsg: (
                <Alert type="error" isOpen onCloseAlert={() => { }}>
                  {oError.response.data}
                </Alert>
              )
            },
            () => this.alertWrapper?.current?.scrollIntoView()
          );
        } else {
          this.setState(
            {
              localSend: false,
              rcmpAlertMsg: (
                <Alert type="error" isOpen onCloseAlert={() => { }}>
                  {Object.keys(oError.response.data.errors).map((cKey, i) => (
                    <p> - {oError.response.data.errors[cKey]}</p>
                  ))}
                </Alert>
              )
            },
            () => this.alertWrapper?.current?.scrollIntoView()
          );
        }
      });
  };

  openScopeEdit = e => {
    e.preventDefault();
    const { norm_nid, oRequisites } = this.state;
    this.setState({
      rcmpAlert: (
        <SweetAlert
          // customClass={`modal-edit lg ${cType === 'linkdocument' ? 'disabled-overflow' : ''} ${
          //   cType === 'newdoc' ? 'force-overflow' : ''
          //   }`}
          customClass="modal-edit md ScrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('Audit.escopo_auditoria')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <ScopeEdit
              onSuccess={cMsg => {
                this.successMessage(cMsg);
              }}
              setRequisites={aRequisites => {
                this.setScopeRequisistes(aRequisites);
              }}
              oRequisites={oRequisites}
              closeModal={this.hideAlert}
              NormNid={norm_nid}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  checkIfUserCanCreateEdit = () => {
    const { oAudit } = this.state;
    const nUserId = Number(oLocalStorage.get('nUserId'));
    const cModule = 'audit-management';
    return (
      can('admin', cModule) ||
      (can('create-edit-audit', cModule) &&
        oAudit.audit_responsibles?.some((oUsrRes) => oUsrRes.user.user_nid === nUserId))
    );
  };

  onChangeEditor = cContent => {
    this.setState({
      cEditorContent: cContent
    });
  };

  editorInstance = oEditor => {
    this.setState({
      oEditor
    });
  };

  render() {
    const {
      bLoading,
      aNorms,
      aUsers,
      aLocals,
      audiInternal,
      norm_nid,
      rcmpAlert,
      rcmpAlertMsg,
      localSend,
      selectedNorm,
      aSelectedResponsibles,
      aSelectedAuditors,
      selectedLocal,
      audi_cdescription,
      audi_dexecutionbegin,
      audi_dexecutionend,
      audi_dexecutionbeginTime,
      audi_dexecutionendTime,
      oAudit,
      cEditorContent
    } = this.state;

    const { closeModal } = this.props;

    return (
      <Page loading={bLoading ? 1 : 0}>
        <div ref={this.alertWrapper} style={{ paddingTop: 5 }}>
          {rcmpAlert}
          {rcmpAlertMsg}
        </div>

        <form onSubmit={oAudit ? e => this.updateAudit(e) : e => this.saveAudit(e)} className="form">
          <label className="split1">
            {intl.get('Audit.norma_label')} <span className="required">*</span>
            <Select
              className="selectCustom select selectNorm"
              classNamePrefix="react-select"
              noOptionsMessage={() => intl.get('sem_opcoes')}
              required
              placeholder={intl.get('Norm.norma_base')}
              isDisabled={oAudit ? (this.checkIfUserCanCreateEdit() === false ? true : false) : false}
              isSearchable
              defaultValue={selectedNorm}
              value={aNorms.filter(option => option.value === norm_nid)}
              options={aNorms}
              onChange={elSelectedOption => {
                this.handleChangeCustom(elSelectedOption, 'norm_nid');
              }}
            />
          </label>

          <div style={{ marginBottom: '20px' }} id="section-desc">
            <p>{intl.get('Requisite.cdescricao')}</p>
            <div className="document-editor__toolbar requisite-editor" />

            <FroalaEditorComponent
              bSimpleEditor
              model={cEditorContent}
              fnHandleChange={evtChange => this.onChangeEditor(evtChange)}
              config={{
                useClasses: true,
                videoUploadURL: `${url.cBase}api/audit/upload`,
                videoUploadParam: 'fVideo',
                videoUploadParams: {
                  nAreaId: oAudit ? oAudit.audi_nid : '',
                  cArea: 'audit'
                },
                imageUploadURL: `${url.cBase}api/audit/upload`,
                imageUploadParam: 'fImage',
                imageUploadParams: {
                  nAreaId: oAudit ? oAudit.audi_nid : '',
                  cArea: 'audit'
                },
                requestHeaders: {
                  Authorization: `Bearer ${localStorage.getItem('cAccessToken')}`
                },
                fontSizeUnit: 'pt',
                entities: ''
              }}
              fnRef={this.editorInstance}
            />
          </div>

          <label className="split1">
            {intl.get('Audit.auditor_responsavel_label')}
            <Select
              className="selectCustom select"
              classNamePrefix="react-select"
              noOptionsMessage={() => intl.get('sem_opcoes')}
              placeholder={intl.get('Audit.auditor_responsavel_placeholder')}
              isClearable
              isMulti
              isSearchable
              defaultValue={aSelectedResponsibles}
              value={aUsers.filter(option => aSelectedResponsibles.includes(option.value))}
              options={aUsers}
              onChange={elSelectedOption => this.handleChangeCustom(elSelectedOption, 'aSelectedResponsibles')}
            />
          </label>

          <label className="split1">
            {intl.get('Audit.auditor_label')} <span className="required">*</span>
            <Select
              className="selectCustom select"
              classNamePrefix="react-select"
              noOptionsMessage={() => intl.get('sem_opcoes')}
              placeholder={intl.get('Audit.auditor_placeholder')}
              isClearable
              isMulti
              isSearchable
              defaultValue={aSelectedAuditors}
              value={aUsers.filter(option => aSelectedAuditors.includes(option.value))}
              options={aUsers}
              onChange={elSelectedOption => this.handleChangeCustom(elSelectedOption, 'aSelectedAuditors')}
            />
          </label>

          <label className="split1">
            {intl.get('Audit.local_label')}
            <Select
              className="selectCustom select"
              classNamePrefix="react-select"
              noOptionsMessage={() => intl.get('sem_opcoes')}
              placeholder={intl.get('Audit.local_placeholder')}
              isClearable
              isSearchable
              defaultValue={selectedLocal}
              options={aLocals}
              onChange={elSelectedOption => this.handleChangeCustom(elSelectedOption, 'locl_nid')}
            />
          </label>

          <AltCheckbox
            onChange={e => this.handleChange(e)}
            title={intl.get('Audit.auditoria_interna')}
            label={intl.get('Audit.auditoria_interna')}
            name="audiInternal"
            localeLabel="start"
            className="split1 custom-padding alt-checkbox-audit"
            checked={audiInternal}
          />

          <label className="split1 timeInputs">
            <p>
              {intl.get('Audit.periodo_label')} <span className="required">*</span>
            </p>
            <div className="containerInputs">
              <input
                onChange={e => this.handleChange(e)}
                id="audiDexecutionbegin"
                name="audi_dexecutionbegin"
                type="date"
                required
                maxLength="255"
                defaultValue={audi_dexecutionbegin}
                max="2100-12-31"
              />

              <input
                onChange={e => this.handleChange(e)}
                id="audiDexecutionbeginTime"
                name="audi_dexecutionbeginTime"
                type="time"
                maxLength="255"
                defaultValue={audi_dexecutionbeginTime}
              />
              <span className="mark">{intl.get('Audit.pediodo_ate')}</span>
              <input
                onChange={e => this.handleChange(e)}
                id="audiDexecutionend"
                name="audi_dexecutionend"
                type="date"
                required
                maxLength="255"
                defaultValue={audi_dexecutionend}
                max="2100-12-31"
              />
              <input
                onChange={e => this.handleChange(e)}
                id="audiDexecutionendTime"
                name="audi_dexecutionendTime"
                type="time"
                maxLength="255"
                defaultValue={audi_dexecutionendTime}
              />
            </div>
          </label>

          {(norm_nid || oAudit) && (
            <button
              type="button"
              onClick={e => this.openScopeEdit(e)}
              title={intl.get('Audit.editar_escopo')}
              className={`btn btn-secondary small-bt scope-edit`}
            >
              {intl.get('Audit.editar_escopo').toUpperCase()}
            </button>
          )}

          <div className="button-stack">
            <button type="button" onClick={() => closeModal()} className="btn alternate back btn btn-outline-secondary">
              {intl.get('cancelar')}
            </button>
            <button
              type="submit"
              disabled={localSend}
              title={intl.get('salvar')}
              className={`btn btn-secondary ${localSend ? 'disabled' : ''} `}
            >
              {intl.get('salvar')}
            </button>
          </div>
        </form>
      </Page>
    );
  }
}

export default Audit;
