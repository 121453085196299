import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import queryString from 'query-string';
import intl from 'react-intl-universal';
import Axios from '../../config/Axios';
import Alert from '../../components/Alert';
import PageClean from '../default-v2/PageClean';
import { deleteRequest, validateRequest } from '../../actions/ActionRequest';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import Select from 'react-select';
import { checkModule } from '../../config/Permissions';

export class RequestCreate extends Component {
  bIsNeedNewRequest = false;

  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      oVersion: undefined,
      aUsers: [],
      nUserDestinyId: oProps.nUserDestinyId ? oProps.nUserDestinyId : '',
      nVersionId: oProps.match ? oProps.match.params.nVersionId : oProps.nVersionId,
      cType: oProps.match ? queryString.parse(oProps.location.cSearch).cType : oProps.cType,
      cTitle: oProps.cTitle ? oProps.cTitle : '',
      cJustification: oProps.cJustification ? oProps.cJustification : '',
      nRequestId: oProps.nRequestId ? oProps.nRequestId : ''
    };
  }

  componentDidMount() {
    const { bIsNewRequest, history } = this.props;
    checkModule(history, 'documents');
    this.bIsNeedNewRequest = bIsNewRequest !== null && bIsNewRequest !== undefined && !bIsNewRequest;

    if (this.bIsNeedNewRequest) {
      this.onValidateRequest();
    } else {
      this.fetchData();
    }
  }

  fetchData = () => {
    const { nVersionId, cType } = this.state;
    const { setAlert, closeModal, onError } = this.props;
    const bIsSuggestionSet = cType && cType !== undefined && cType === 'update';

    Axios.get('/request/form/create', {
      params: {
        nVersionId: nVersionId !== undefined ? nVersionId : '',
        cType
      }
    })
      .then(oResponse => {
        const { aUsers, oVersion } = oResponse.data;
        let { nUserDestinyId } = this.state;
        let aSelectedUsers = [];

        if (oVersion !== null && oVersion !== undefined && oVersion !== '') {
          nUserDestinyId = oVersion.document.document_responsibles[0].user_nid;
        }

        Object.keys(aUsers).forEach(cKey => {
          aSelectedUsers.push({
            label: aUsers[cKey].user_cname,
            value: Number(aUsers[cKey].user_nid)
          });
        });

        if (aUsers && aUsers.length === 0 && onError && onError !== undefined) {
          onError(intl.get('RequestCreate.error_nousers'));
        } else if (bIsSuggestionSet) {
          this.setState({
            bLoading: false,
            bIsSending: false,
            aUsers: aSelectedUsers,
            oVersion,
            nUserDestinyId,
            cTitle: oVersion.document.doc_ctitle
          });
        } else {
          this.setState({
            bLoading: false,
            bIsSending: false,
            aUsers: aSelectedUsers,
            oVersion,
            nUserDestinyId
          });
        }
      })
      .catch(oError => {
        this.setState({
          bLoading: false,
          bIsSending: false
        });
        if (closeModal) {
          this.setState({
            bLoading: false,
            bIsSending: false,
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => {}}>
                {oError.aMsgErrors || intl.get('RequestCreate.erro_criando')}
              </Alert>
            )
          });
        } else {
          setAlert('error', oError.aMsgErrors);
        }
      });
  };

  onValidateRequest = () => {
    const { nRequestId } = this.state;
    validateRequest(nRequestId, 29)
      .then(oResponse => {
        this.fetchData();
      })
      .catch(oError => {
        this.setState({
          bLoading: false,
          bIsSending: false,
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => {}}>
              {oError.aMsgErrors}
            </Alert>
          )
        });
      });
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleChangeUser = (elSelectedOption, cType) => {
    this.setState({ [cType]: elSelectedOption.value });
  };

  handleSubmit = evt => {
    evt.preventDefault();

    this.setState({ bIsSending: true });

    const { nUserDestinyId, oVersion, cTitle, cJustification, cType, nVersionId, nRequestId } = this.state;
    const { setAlert, closeModal, history, onSuccess } = this.props;
    if (!this.bIsNeedNewRequest) {
      Axios.post('/request', {
        user_nid_destiny: nUserDestinyId,
        ver_nid: nVersionId,
        doc_nid: oVersion ? oVersion.doc_nid : '',
        rqst_ctitle: cTitle,
        rqst_cjustification: cJustification,
        rqst_etype: cType,
        rqst_nid_old: nRequestId
      })
        .then(() => {
          this.setState({ bIsSending: false });

          if (nVersionId && !onSuccess) {
            history.push({
              pathname: '/',
              state: { aAlertMessages: { cType: 'success', cMsg: intl.get('RequestCreate.sucesso_criando') } }
            });
          } else {
            onSuccess(intl.get('RequestCreate.sucesso_criando'));
          }
        })
        .catch(oError => {
          if (nVersionId) {
            setAlert('error', oError.aMsgErrors);
            closeModal();
            this.setState({
              bIsSending: false
            });
          } else {
            this.setState({
              rcmpAlertMsg: (
                <Alert type="error" isOpen onCloseAlert={() => {}}>
                  {oError.aMsgErrors || intl.get('RequestCreate.erro_criando')}
                </Alert>
              ),
              bIsSending: false
            });
          }
        });
    } else {
      Axios.put('/request', {
        rqst_nid: nRequestId,
        user_nid_destiny: nUserDestinyId,
        rqst_ctitle: cTitle,
        rqst_cjustification: cJustification,
        cAction: 'pending',
        stat_nid: 29,
        bIsRequester: 1
      })
        .then(oResponse => {
          onSuccess(oResponse.data.success);
        })
        .catch(oError => {
          this.setState({
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => {}}>
                {oError.aMsgErrors}
              </Alert>
            ),
            bIsSending: false
          });
        });
    }
  };

  getTitle = () => {
    const { cType, oVersion } = this.state;

    if (cType === 'delete') {
      return <h1> {intl.get('FolderIndex.label_solicitar_envio_arquivo')}</h1>;
    }
    if (oVersion) {
      return (
        <h1>
          {intl.get('RequestCreate.solicitar_nova_versao')} : {oVersion.document.doc_ctitle}
        </h1>
      );
    }
    return <h1> {intl.get('SmartButton.request_document')}</h1>;
  };

  confirmRemoveRequest = () => {
    this.setState({ bIsSending: true });
    const { nRequestId } = this.state;
    const { onSuccess } = this.props;

    deleteRequest(nRequestId)
      .then(oResponse => {
        onSuccess(oResponse.data.success);
      })
      .catch(oError => {
        this.setState({
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => {}}>
              {oError.aMsgErrors}
            </Alert>
          ),
          bIsSending: false
        });
      });
  };

  onRemoveRequest = evt => {
    evt.preventDefault();
    this.setState({
      rcmpAlertMsg: (
        <SweetAlert
          danger
          title=""
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          showCancel
          onCancel={this.hideAlert}
          onConfirm={evt => this.confirmRemoveRequest()}
        >
          {intl.get('Request.remover_pergunta')}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlertMsg: null
    });
  };

  renderListRequested = bIsSuggestionSet => {
    const { aUsers, nUserDestinyId, oVersion } = this.state;

    if (!bIsSuggestionSet) {
      return (
        <Input type="select" required name="nUserDestinyId" onChange={this.handleChange} value={nUserDestinyId}>
          <option value="">{intl.get('RequestCreate.selecione_responsavel')}</option>
          {aUsers.map(oUser => (
            <option key={oUser.user_nid} value={oUser.user_nid}>
              {oUser.user_cname}
            </option>
          ))}
        </Input>
      );
    }

    return (
      <Input
        type="text"
        id="nUserDestinyName"
        name="nUserDestinyName"
        required
        readOnly={bIsSuggestionSet}
        value={oVersion.document.document_responsibles[0].user_cname}
      ></Input>
    );
  };

  render() {
    const {
      aUsers,
      cTitle,
      cJustification,
      bIsSending,
      rcmpAlertMsg,
      oVersion,
      cType,
      bLoading,
      nUserDestinyId
    } = this.state;
    const { history, closeModal, nQtyCharacters } = this.props;
    const bIsSuggestionSet = cType && cType != undefined && cType == 'update' ? true : false;
    const nNewQtyCharacters = nQtyCharacters === null || nQtyCharacters === undefined ? 250 : nQtyCharacters;

    return (
      <PageClean loading={bLoading ? 1 : 0}>
        {rcmpAlertMsg}
        {this.getTitle()}

        {aUsers.length > 0 && (
          <form className="form" onSubmit={this.handleSubmit}>
            <label>
              {intl.get('requested')} <span className="required">*</span>
              <Select
                className="selectCustom"
                classNamePrefix="react-select"
                options={aUsers}
                placeholder={intl.get('RequestCreate.selecione_responsavel')}
                value={
                  nUserDestinyId
                    ? aUsers.filter(oUserItem => {
                        return nUserDestinyId === oUserItem.value;
                      })
                    : ''
                }
                onChange={elSelectedOption => this.handleChangeUser(elSelectedOption, 'nUserDestinyId')}
              />
            </label>

            <label>
              {intl.get('titulo')} <span className="required">*</span>
              <Input
                type="text"
                id="cTitle"
                name="cTitle"
                maxLength="100"
                required
                readOnly={bIsSuggestionSet}
                onChange={evt => this.handleChange(evt)}
                value={cTitle}
              />
            </label>

            <label>
              {bIsSuggestionSet ? intl.get('RequestCreate.suggestion') : intl.get('ConfigHeaderFooter.justification')}{' '}
              <span className="required">*</span>
              <Input
                type="textarea"
                required
                maxLength={nNewQtyCharacters}
                name="cJustification"
                cols="50"
                rows="10"
                onChange={this.handleChange}
                value={cJustification}
              />
            </label>
            <div className="button-stack">
              {closeModal ? (
                <Button type="button" className="btn alternate back" onClick={() => closeModal()}>
                  {intl.get('cancelar')}
                </Button>
              ) : (
                <Button type="button" className="btn alternate alert" onClick={() => history.goBack()}>
                  {intl.get('cancelar')}
                </Button>
              )}
              {this.bIsNeedNewRequest ? (
                <Button
                  name="action"
                  value="remove"
                  type="submit"
                  className="btn btn-danger btn-requestReject"
                  onClick={this.onRemoveRequest}
                  disabled={bIsSending}
                >
                  {intl.get('remover')}
                </Button>
              ) : (
                ''
              )}
              <Button disabled={bIsSending} type="submit" className="btn">
                {this.bIsNeedNewRequest
                  ? intl.get('salvar')
                  : cType === 'delete' || !oVersion
                  ? intl.get('solicitar')
                  : intl.get('suggest')}
              </Button>
            </div>
          </form>
        )}
      </PageClean>
    );
  }
}

export default RequestCreate;
