import React, { Component } from 'react';
import intl from 'react-intl-universal';
import PageClean from '../default-v2/PageClean';
import { Button, Input } from 'reactstrap';
import { downloadPDF } from '../../actions/ActionDocumentDetails';
import { Alert } from '../../components/Alert';
import { checkModule } from '../../config/Permissions';

class PrintConfig extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: false,
      rcmpAlertMsg: '',
      rcmpPrintMsg: '',
      oPrintConfig: {
        cFormat: 'A4',
        cOrientation: 'P',
        cPreview: 'N',
        cServerPrint: 'P'
      }
    };
  }

  componentDidMount = () => {
    const { history } = this.props;
    checkModule(history, 'documents');
  };

  handleSubmit = evtSubmit => {
    const { nVersionId, cDocName, closeModal, cPreview, cServerPrint} = this.props;
    const { oPrintConfig } = this.state;
    this.setState({
      rcmpPrintMsg: intl.get('PrintConfig.wait_downloading_pdf'),
      bLoading: true
    });

    downloadPDF(nVersionId, cDocName, oPrintConfig.cFormat, oPrintConfig.cOrientation, cPreview, oPrintConfig.cServerPrint)
      .then(oResponse => {
        closeModal();
      })
      .catch(() => {
        this.setState({
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => {}}>
              {intl.get('DocumentDetails.download_pdf_message')}
            </Alert>
          ),
          rcmpPrintMsg: '',
          bLoading: false
        });
      });
  };

  handleChange = evtChange => {
    const { oPrintConfig } = this.state;
    oPrintConfig[evtChange.target.name] = evtChange.target.value;
    this.setState({ oPrintConfig });
  };

  render() {
    const { bLoading, rcmpAlertMsg, rcmpPrintMsg, oPrintConfig } = this.state;
    const { closeModal } = this.props;

    return (
      <>
        {rcmpPrintMsg !== '' && <span className="message">{rcmpPrintMsg}</span>}
        <PageClean loading={bLoading ? 1 : 0}>
          <form className="form" onSubmit={this.handleSubmit}>
            {rcmpAlertMsg}
            <label>
              {intl.get('PrintConfig.format_title')}
              <Input
                type="select"
                name="cFormat"
                id="cFormat"
                onChange={this.handleChange}
                value={oPrintConfig.cFormat}
              >
                <option value="A4">A4</option>
              </Input>
            </label>

            <label>
              {intl.get('PrintConfig.oritentation_title')}
              <Input
                type="select"
                name="cOrientation"
                id="cOrientation"
                onChange={this.handleChange}
                value={oPrintConfig.cOrientation}
              >
                <option value="P">{intl.get('PrintConfig.portrait')}</option>
                <option value="L">{intl.get('PrintConfig.landscape')}</option>
              </Input>
            </label>

            <label>
              {intl.get('PrintConfig.server_print')}
              <Input
                type="select"
                name="cServerPrint"
                id="cServerPrint"
                onChange={this.handleChange}
                value={oPrintConfig.cServerPrint}
              >
                <option value="P">{intl.get('PrintConfig.server_novo1')}</option>
                <option value="L">{intl.get('PrintConfig.server_padrao')}</option>
                {/* <option value="N">{intl.get('PrintConfig.server_novo2')}</option> */}
              </Input>
            </label>

            <div className="button-stack">
              <Button type="button" onClick={closeModal} className="btn btn-cancel">
                {intl.get('cancel')}
              </Button>
              <Button type="submit">{intl.get('print')}</Button>
            </div>
          </form>
        </PageClean>
      </>
    );
  }
}

PrintConfig.defaultProps = {
  closeModal: () => {},
  nVersionId: null,
  cDocName: '',
  cPreview: 'N',
  cServerPrint: 'P'
};

export default PrintConfig;
